.NavigationMenuContent[data-state="open"] {
  animation: scaleIn 300ms ease;
}
.NavigationMenuContent[data-state="closed"] {
  animation: scaleOut 300ms ease;
}

.NavigationMenuContent {
  margin-top: 15px;
  width: 100%;
  margin-left: -190px;
}

@media only screen and (min-width: 600px) {
  .NavigationMenuContent {
    width: auto;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: rotateX(-30deg) scale(0.9);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
  to {
    opacity: 0;
    transform: rotateX(-10deg) scale(0.95);
  }
}
